<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../../public/images/IPC/lowPowerConsumption/download.jpg" width="100%">
    <div class="pet">
      <div class="content3_box">
        <div class="content3_box_1" @click="bigcell">
          <img src="../../../../public/images/IPC/lowPowerConsumption/1.jpg" width="100%" height="100%">
          <div class="content3_box_sise">低功耗电池摄像机</div>
        </div>
        <div class="content3_box_1" @click="solarEnergy">
          <img src="../../../../public/images/IPC/lowPowerConsumption/2.jpg" width="100%" height="100%">
          <div class="content3_box_sise">太阳能低功耗摄像机</div>
        </div>
        <div class="content3_box_1" @click="littlecell">
          <img src="../../../../public/images/IPC/lowPowerConsumption/3.jpg" width="100%" height="100%">
          <div class="content3_box_sise">微型电池摄像机</div>
        </div>
      </div>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../../src/components/elMenu'
import foot from '../../../../src/components/foot'
import navigation from '../../../../src/components/navigation'

export default {
  name: "lowPowerConsumption",
  components: {
    elMenu, foot,navigation
  },
  methods:{
    solarEnergy(){
      this.$router.push('/solarEnergy')
    },
    bigcell(){
      this.$router.push('/bigcell')
    },
    littlecell(){
      this.$router.push('/littlecell')
    },
  }
}
</script>

<style scoped>
.pet {
  height: 750px;
}

.content3_box {
  padding-top: 150px;
  display: flex;
  justify-content: space-around;
}

.content3_box_1 {
  width: 20%;
  height: 400px;
  border: 1px solid #ececec;
}

.content3_box_sise {
  line-height: 100px;
  text-align: center;
  font-size: 18px;
}
</style>